import React, { cloneElement } from 'react';
import useAuth from '../hooks/useAuth';
import { PERMISSIONS } from '../permissionMaps';
import AccessDeniedPage from '../components/Pages/AccessDeniedPage';
import _ from 'lodash';

const hasPermission = ({ permissions, scopes }) => {
    const scopesMap = {};
    scopes.forEach((scope) => {
        scopesMap[scope] = true;
    });
    return permissions?.some((permission) => scopesMap[permission]) || false;
};

const getPermissions = ({ roles }) => {
    var permissions = [];
    if (roles?.role.length > 0) {
        roles.role.forEach((role) => {
            if (!permissions.includes(PERMISSIONS[role]) && PERMISSIONS[role]) {
                permissions.push(...PERMISSIONS[role]);
            }
        });
    }
    return permissions;
};

export function isAdmin(user) {
    var roles = [];
    if (user?.user?.roles !== undefined) {
        roles = JSON.parse(JSON.parse(JSON.stringify(user.user.roles)));
    }
    return _.includes(roles.role, 'Admin');
}

export function getDefaultModel(user) {
    var roles = [];
    if (user?.user?.roles !== undefined) {
        roles = JSON.parse(JSON.parse(JSON.stringify(user.user.roles)));
    }
    return {
        label: 'VMEX GenAI',
        value: 'VMEXGenAI',
        dataSetId: '',
    };
    // return _.includes(roles.role, 'Admin') ||
    //     _.includes(roles.role, 'can-manage-genai') ||
    //     _.includes(roles.role, 'can-queryvmexmodel-genai')
    //     ? {
    //           label: 'VMEX GenAI',
    //           value: 'VMEXGenAI',
    //           dataSetId: '',
    //       }
    //     : {
    //           label: 'Gemini 1.5 Flash (BETA)',
    //           value: 'VertexGemini',
    //           dataSetId: '',
    //       };
}

export function hasUserPermission(user, permissions, includeAdmin = true) {
    var roles = [];
    if (user?.user?.roles !== undefined) {
        roles = JSON.parse(JSON.parse(JSON.stringify(user.user.roles)));
    }
    var includes = false;
    _.forEach(permissions, (permission) => {
        if (roles.role.includes(permission)) {
            includes = true;
        }
    });
    return includes;
}

// export function isAuthorized(user, scopes) {
//     var roles = [];
//     if (user?.user?.roles !== undefined) {
//         roles = JSON.parse(JSON.parse(JSON.stringify(user.user.roles)));
//     }
//     const permissions = getPermissions({ roles });
//     // const permissionGranted = hasPermission({ permissions, scopes });
//     //console.log('permissions', permissions);
//     //console.log('permissionGranted', permissionGranted);
//     return [];
// }

export default function PermissionsGateContext({
    children,
    hideComponent,
    errorProps = null,
    scopes = [],
    isDisable = false,
}) {
    const { user } = useAuth();
    if (scopes.length > 0) {
        var roles = [];
        if (user?.user?.roles !== undefined) {
            roles = JSON.parse(JSON.parse(JSON.stringify(user.user.roles)));
        }
        const permissions = getPermissions({ roles });
        const permissionGranted = hasPermission({ permissions, scopes });
        if (isDisable && !permissionGranted) {
            return cloneElement(children, { disabled: false, ...errorProps });
        }
        if (!permissionGranted && !errorProps) {
            if (hideComponent) {
                return <React.Fragment></React.Fragment>;
            }
            return <AccessDeniedPage />;
        }

        if (!permissionGranted && errorProps)
            return cloneElement(children, { ...errorProps });
    }

    return <React.Fragment>{children}</React.Fragment>;
}
